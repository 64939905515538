import * as React from 'react';
import Header from '../components/craftkitchens/Header/Header';
import Footer from '../components/craftkitchens/Footer/Footer';
import SEO from '../components/SEO/SEO';
import favicon from '../images/craft-favicon.png';

interface LayoutProps {
	children: any;
	title: string;
	desc: string;
	headerType: string;
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
	return (
		<>
			<SEO favicon={favicon} title={props.title} desc={props.desc} />
			<Header type={props.headerType} />
			<main>{props.children}</main>
			<Footer />
		</>
	);
};

export default Layout;
