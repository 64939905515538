import * as React from 'react';

import logo from '../../../images/craft-kitchens-logo.svg';

interface HeaderProps {
	type: string;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
	return (
		<header className="relative z-[100] bg-transparent antialiased">
			<div className="container mx-auto flex items-center justify-between bg-transparent px-6 py-5">
				<a href="https://craftkitchens.com/" className="h-full pl-1">
					<img
						className=""
						src={logo}
						alt="Craft Kitchens Logo"
						width={312}
						height={46}
					/>
				</a>
			</div>
		</header>
	);
};

export default Header;
