import * as React from 'react';
// @ts-ignore
import hero from '../../../images/craft-hero.png';

const Banner: React.FC = () => {
	return (
		<section className="">
			<div className="container mx-auto flex w-full justify-between gap-20 px-6 py-20 text-black max-lg:flex-col max-lg:gap-6 max-lg:py-10">
				<div className="w-full">
					<h1 className="font-song-myung text-[33px] font-bold">
						Careers
					</h1>
					<p className="my-6 font-work-sans text-lg">
						At Craft Kitchens, we're proud of the high-quality grab
						and go items we produce and provide to many business
						sectors including convenience stores, schools, vending
						companies, hospitals and airports. Our menu includes a
						wide range of sandwiches, wraps, salads, pre-made meals,
						and snacks, making it easy for busy individuals to fuel
						up on-the-go.
					</p>
					<p className="my-6 font-work-sans text-lg">
						We're proud of what we've accomplished and we're
						passionate about what we do. Join us on our journey to
						provide delicious and nutritious grab and go options
						throughout Missouri.
					</p>
					<p className="my-6 font-work-sans text-lg">
						Get Paid Weekly – Your Hard Work, Rewarded Faster!
					</p>
				</div>
				<img
					src={hero}
					alt="Working at craftkitchens"
					width={846}
					height={619}
					className="w-full max-w-[400px]"
				/>
			</div>
		</section>
	);
};

export default Banner;
