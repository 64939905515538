import * as React from 'react';

const Footer: React.FC = () => {
	return (
		<footer className={'bg-black text-white'}>
			<div className={'container mx-auto py-16 px-6'}>
				<div className="mx-auto flex items-center justify-start max-sm:flex-col max-sm:items-start max-sm:gap-12">
					<div className="w-full max-w-[40%]">
						<p className="mb-7 font-song-myung  text-3xl">
							Location
						</p>
						<p className="my-0 font-work-sans">815 Fee Fee Road</p>
						<p className="my-0 font-work-sans">
							Maryland Heights, MO 63043
						</p>
					</div>
					<div>
						<p className="mb-7 font-song-myung  text-3xl">
							Contact
						</p>
						<p className="my-0 font-work-sans">
							Phone: (636) 326-6306
						</p>
						<a
							className="text-white transition-all hover:text-white hover:opacity-60"
							href="https://craftkitchens.com/get-started/"
						>
							<p className="my-0 font-work-sans">
								Send us an email
							</p>
						</a>
					</div>
				</div>
			</div>
			<hr className="bg-[rgba(119,119,119,0.87)]" />
			<div className={'container mx-auto py-8 px-6'}>
				<p className="mt-12 font-work-sans text-sm  text-white">
					© {new Date().getFullYear()} Craft Kitchens By Wallis
					Companies.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
