import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../../layouts/CraftKitchensLayout';
import Banner from '../../components/craftkitchens/Banner/Banner';
import JobSearch from '../../components/craftkitchens/JobSearch/JobSearch';

const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	function filterByBrand(edges: any[], brandToFilter: string) {
		return edges.filter(
			(page) => page.node.pageContext.brand === brandToFilter
		);
	}

	data.allSitePage.edges = filterByBrand(
		data.allSitePage.edges,
		'Craft Kitchens'
	);

	return (
		<Layout
			headerType="light"
			title="Craft Kitchens | Employment Opportunities"
			desc="Craft Kitchens | Employment Opportunities"
		>
			<Banner />
			<JobSearch data={data} />
		</Layout>
	);
};
export default SearchPage;
